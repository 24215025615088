import React, { useState, useEffect } from 'react';
import Header from "./components/Header";
import NoData from "./components/NoData";
import jobsIcon from './assets/jobs.svg';
import questionMark from './assets/questionMark.a9d7a2b2c2614da1ba5af9e432208766.svg';
import './App.css';
import CustomerInfo from "./components/CustomerInfo";
import JobsListing from "./components/CustomerJobs/JobsListing";
import { BrowserRouter as Router, Routes, Route, useParams, Navigate, useNavigate } from 'react-router-dom';

// Define the type of userData
interface UserData {
  id: string;
  name: string;
  email: string;
  avatar: string;
  businessName: string;
  stripeCustomerId: string;
}


// Your functional component
const CustomerData: React.FC = () => {
  // Use useParams hook to get parameters from the URL
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [userData, setUser] = useState<UserData | null>(null);
  const [userJobsData, setJobsUser] = useState<{ user?: any }>({});
  const url = "https://pro.postal.com.au:3000";

  console.log('USER data', userData)

  const user_id = id;

  const fetchData = async () => {
    try {
      const response = await fetch(`${url}/customerInfo/${user_id}`);
      if (!response.ok) {
        throw new Error(`Error fetching user data: ${response.statusText}`);
      }
      const result = await response.json();
      setUser(result?.user);
      const userEmail = result?.user?.email;
      try {
        const response1 = await fetch(`${url}/customerJobs/${userEmail}`);
        if (!response1.ok) {
          navigate('/apps');
          throw new Error(`Error fetching jobs data: ${response1.statusText}`);

        }
        const result1 = await response1.json();
        setJobsUser(result1?.jobs?.data);
      } catch (error) {
        navigate('/apps');
        console.error('Error fetching jobs data:', error);
      }
    } catch (error) {
      navigate('/apps');
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user_id]);

  return (
    <div className="App" >
      <Header />
      {userData ?
        <><div className="staff">
          <div className="staff-listing" style={{
            marginTop: 24,
            marginLeft: 24,
            marginRight: 24,
            marginBottom: 24
          }}>
            <div className="column-header">
              <h2 className="column-heading" style={{ marginBottom: 0, marginTop: 0, fontWeight: 400 }}>
                Business Name
              </h2>
              <div style={{ position: 'relative', width: 'fit-content' }}>
                <img
                  src={questionMark}
                  style={{ cursor: 'pointer', marginBottom: '-4px', marginLeft: '5px' }}
                  alt="Question Mark" />
              </div>
            </div>
            <div style={{
              fontWeight: 'bold',
              fontSize: '24px',
              textAlign: 'center',
              padding: '10px 0',
            }}>
              {userData?.businessName}
            </div>
          </div>
        </div>
          <div className="jobs">
            <div className="column-header">
              <h2 className="column-heading" style={{ marginBottom: 0, marginTop: 0, fontWeight: 400 }}>
                Active Apps
              </h2>
              <div style={{ position: 'relative', width: 'fit-content' }}>
                <img
                  src={questionMark}
                  style={{ cursor: 'pointer', marginBottom: '-4px', marginLeft: '5px' }}
                  alt="Question Mark" />
              </div>
            </div>
            <div className="list"></div>
            <JobsListing jobs={userJobsData} />
          </div></>
        :
        <NoData icon={jobsIcon} title={'User not found'} />
      }

    </div>
  );
};


const EmptyData: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <NoData icon={jobsIcon} title={'No data found'} />
    </div>
  );
};


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/apps/:id" element={<CustomerData />} />
        <Route path="/apps/" element={<EmptyData />} />
        <Route path="/" element={<Navigate to="/apps/:id" />} />
      </Routes>
    </Router>
  );
};

export default App;