import Avatar from '../../components/Avatar/Avatar'
import NoData from '../NoData';
import jobsIcon from '../../assets/jobs.svg';
interface IActiveJobItemProps {
  jobs: any
}

/** Edit by sandeep end */
const ActiveJobItem = ({
  jobs,
}: IActiveJobItemProps) => {

  return (
    <div>
      {jobs && jobs.length > 0 ?
        jobs.map((job: any) =>
          <div key={job?.id} className="job-item">
            <div className="left">
              <Avatar
                size={56}
                name={job?.appIndustry}
                url={job?.appImage ? job?.appImage : `https://cdn4.iconfinder.com/data/icons/the-weather-is-nice-today/64/weather_24-512.png`}
              />
              <div className="details">
                <div className="title">{job?.appTitle}</div>
                <div className="info">
                  {job?.appIndustry}
                </div>
              </div>
            </div>
            <div className="right"></div>
          </div>
        )
        :
        <NoData
          icon={jobsIcon}
          title="No active apps"
        />
      }
    </div>
  );
};
export default ActiveJobItem
