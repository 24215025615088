import { HTMLAttributes, useMemo } from 'react'

import './avatar.sass'

interface IAvatarProps extends HTMLAttributes<HTMLDivElement> {
  url?: string
  size?: number
  name?: string
}

const AVATAR_BACKGROUNDS = [
  '#4CA7DA',
  '#FF8B00',
  '#8777D9',
  '#36B37E',
  '#FF5630',
]

const Avatar = ({ url, size = 40, name = '', ...props }: IAvatarProps) => {
  const initials = useMemo(() => {
    if (!url && name) {
      const splitName = String(name + ' ').split(' ')
      return `${splitName[0].charAt(0).toUpperCase()}${splitName[1]
        .charAt(0)
        .toUpperCase()}`
    }
    return ''
  }, [url, name])
  const avatarColor = useMemo(() => {
    return AVATAR_BACKGROUNDS[String(name).length % 5]
  }, [name])
  return (
    <div
      {...props}
      style={{
        backgroundColor: avatarColor,
        width: `${size}px`,
        height: `${size}px`,
      }}
      className="avatar"
    >
      {initials ? (
        <span
          className="initials"
          style={{
            fontSize: `${size / 2.8}px`,
          }}
        >
          {initials}
        </span>
      ) : (
        <img src={url} alt={name} />
      )}
    </div>
  )
}

export default Avatar
