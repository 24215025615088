import protonLogo from '../../assets/Proton-accent.svg'
import './customer.sass'
const Header = () => {
  return (
    <div className="onboarding-header" style={{ padding: '0px 24px' }}>
      <div className="logo" style={{ display: 'flex' }}>
        <img src={protonLogo} alt="Proton Logo" />
      </div>
    </div>
  )
}
export default Header
